<template>
    <main >
        <div id="section-to-print">
        <div class="row m-0">
            <div class="col-xl-9 m-auto text-center pt-2">
                <img id="img" src="../assets/logo.jpeg" width="250"/>
            </div>

        </div>

            <table class="mt-5 t1" style="width:90%;margin: auto">
                <tr>
                    <td></td>
                    <td> <h6  style="text-decoration: underline;text-align: right">Marrakech, Le {{new Date(item.date_transaction).toLocaleDateString('fr-FR',options)}}</h6></td>
                </tr>
                <tr>
                    <td><h6>Facture N : {{item.num_facture}}</h6></td>
                    <td> <h6  style="text-decoration: underline;text-align: right"> Client: {{item.client_name}}</h6></td>
                </tr>
                <tr v-if="item.ice">
                    <td><h6>ICE/{{item.ice}}</h6></td>
                    <td></td>
                </tr>
            </table>



        <table  class="table_fact mt-5" >
            <tr>
                <th class="Details">Details</th>
                <th class="Article">Article</th>
                <th class="Qte">Qte</th>
                <th class="pu" v-if="!item.mode">P.U TTC dh</th>
                <th class="pu" v-if="item.mode">P.U HT dh</th>
                <th class="pt" v-if="!item.mode">P.T TTC dh</th>
                <th class="pt" v-if="item.mode">P.T HT dh</th>

            </tr>
            <tr v-for="it in facture_details" :key='it.id'>
                <td style="text-align: left" class="Details">
                    <div   v-for="att in it.attributes" :key='att.id_attribute'>
                    <span><b>{{att.attribute_name}} : </b>{{att.name}}</span><br>
                    </div>
                </td>
                <td class="Article">{{it.article.name}}</td>
                <td class="Qte">{{it.qte}}</td>
                <td  class="pu" v-if="!item.mode">{{it.unit_price}}</td>
                <td  class="pu" v-if="item.mode">{{it.price_ht}}</td>
                <td class="pt">{{it.pt}}</td>

            </tr>
            <tr>
                <th rowspan="3" colspan="3"></th>
                <td v-if="!item.mode">Total TTC</td>
                <td v-if="item.mode">Total HT</td>
                <td>{{total}}</td>
            </tr>
            <tr>
                <td>TVA 20%</td>
                <td>{{tva}}</td>
            </tr>
            <tr>
                <td v-if="!item.mode">Total HT</td>
                <td v-if="item.mode">Total TTC</td>
                <td>{{total_rev}}</td>
            </tr>


        </table>
            <div class="ml-5 mt-3">
                <p><b >Mode de reglement :</b> {{modes[item.mode_reglement]}}</p>
                <div class="footer text-center">
                <p >PRETTY LINGE S.A.R.L. au capitale de 100.000 DH - Numero 317, ICE 000201544000012</p>
                <p >ZI Sidi Ghanem - Route de Safi -Marrakech , RC 38899 Patente 64095027-IF 06523832 CNSS 8149131 Tel 0524 33 58 83</p>
            </div>
            </div>

              </div>
        <div class="row mt-5 pl-5">
            <button class="btn-outline-danger mr-2" onclick="window.close();">Fermer</button>
            <button class="btn-outline-success"  onclick="window.print();">Imprimer</button>
        </div>
    </main>
</template>

<script>


    export default {
        name: "ImprimerFcture",
        created(){
            this.getFacture()
            this.getFactureDetails()
        },
        data(){
            return{
                modes:['Carte Bancaire', 'Virement','Espece','Cheque'],
                facture_details:[],
                item:{},
                options : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
                value: window.location.href,

            }
        },

        methods:{
            getFacture() {
                this.$http
                    .get('factures/' + this.$route.params.id)
                    .then(response => {
                        this.item = response.data
                        this.getFactureDetails()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getFactureDetails() {
                this.$http
                    .get('factures_detail/' + this.$route.params.id)
                    .then(response => {
                        this.facture_details = response.data
                        let a = this.item
                        this.facture_details.forEach(function (i) {
                          if(!a.mode)  i.pt = (parseFloat(i.qte) * parseFloat(i.unit_price)).toFixed(2)
                          else i.pt = (parseFloat(i.qte) * parseFloat(i.price_ht)).toFixed(2)
                        });
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

        },
        computed:{
            total: function () {
                let sum_op = (max, cur) => max + cur;
                let sum = this.facture_details.map(el => parseFloat(el.pt)).reduce(sum_op, 0);
                let ttc = sum * (1 - (this.item.reduction) / 100);
                if (Number.isInteger(ttc)) return ttc
                else return ttc.toFixed(2)
            },
            tva: function () {
               if(!this.item.mode) return (parseFloat(this.total) / 6).toFixed(2)
               else return (parseFloat(this.total) * 0.2).toFixed(2)
            },
            total_rev: function () {
                if(!this.item.mode) return Math.ceil(parseFloat(this.total) - parseFloat(this.tva))
                else return Math.ceil(parseFloat(this.total) + parseFloat(this.tva))
            },


        },

    }
</script>

<style src="@/assets/imprimer.css"></style>